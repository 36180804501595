import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {RneService} from "../../services/rne.service";
import {ToastTool} from "../../tools/toast.tool";
import {HttpErrorResponse} from "@angular/common/http";
import {Rne} from "../../models/rne";
import {EtablissementClient} from "../../models/etablissement-client";
import {debounceTime, distinctUntilChanged, filter, map, merge, Observable, OperatorFunction, Subject} from "rxjs";
import {EtablissementService} from "../../services/etablissement.service";

@Component({
  selector: 'app-dialog-ajout-rne',
  templateUrl: './dialog-form-rne.component.html',
  styleUrls: ['./dialog-form-rne.component.css']
})
export class DialogFormRneComponent {
  @Input() rneSelectionne: Rne;
  @Output() rneModifie = new EventEmitter<Rne>();
  form: UntypedFormGroup;
  loading: boolean = false;

  listeEtablissementClient: EtablissementClient[] = [];

  // Recherche d'établissement
  formatter = (result: EtablissementClient) => result.nom + ' (' + result.crous?.numeroCrous + ')';

  search: OperatorFunction<string, readonly EtablissementClient[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term === '' ? [] : this.listeEtablissementClient.filter((v) => v.nom.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
      ),
    );
    // --

  constructor(private rneService: RneService,
              private fb: FormBuilder,
              public activeModal: NgbActiveModal,
              private etablissementClientService: EtablissementService) {
    this.loadEtablissementClient();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      uairne: this.fb.control<string|null>(null, Validators.required),
      nomUsuel: this.fb.control<string|null>(null, Validators.required),
      libelleCourt: this.fb.control<string|null>(null, Validators.required),
      localite: this.fb.control<string|null>(null, Validators.required),
      etablissementClient: this.fb.control<EtablissementClient|null>(null)
    });

    if (this.rneSelectionne.idRne) {
      this.initFormValues();
    } else {
      this.resetFormValues();
    }
  }

  resetEtablissement() {
    this.form.controls['etablissementClient'].reset();
  }

  loadEtablissementClient() {
    this.loading = true;
    this.etablissementClientService.getListeEtablissementClient().subscribe(
    {
      next: (data) => {
        this.loading = false;
        this.listeEtablissementClient = data;
      },
      error: (err) => {
          this.loading = false;
          ToastTool.danger(err.error_description);
        }
      }
    )
  }

  initFormValues() {
    this.form.controls['uairne'].setValue(this.rneSelectionne?.uairne);
    this.form.controls['uairne'].disable();
    this.form.controls['nomUsuel'].setValue(this.rneSelectionne?.nomUsuel);
    this.form.controls['libelleCourt'].setValue(this.rneSelectionne?.libelleCourt);
    this.form.controls['localite'].setValue(this.rneSelectionne?.localite);
    this.form.controls['etablissementClient'].setValue(this.rneSelectionne?.etablissementClient);
  }

  resetFormValues() {
    this.form.reset();
    this.form.controls['uairne'].enable();
  }

  setValues() {
    this.rneSelectionne.uairne = this.form.controls['uairne']?.value;
    this.rneSelectionne.nomUsuel = this.form.controls['nomUsuel']?.value;
    this.rneSelectionne.libelleCourt = this.form.controls['libelleCourt']?.value;
    this.rneSelectionne.localite = this.form.controls['localite']?.value;
    this.rneSelectionne.etablissementClient = this.form.controls['etablissementClient']?.value;
  }

  save() {
    if (this.rneSelectionne.idRne) {
      this.modifierRne();
    } else {
      this.ajouterRne();
    }
  }

  ajouterRne() {
    this.setValues();
    this.loading = true;
    this.rneService.postRne(this.rneSelectionne).subscribe({
      next:() => {
        this.loading = false;
        ToastTool.success('Rne créé');
        this.rneModifie.emit();
        this.activeModal.close();
      },
      error: (err: HttpErrorResponse) => {
        this.loading = false;
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
        this.rneModifie.emit();
      }
    });
  }

  modifierRne() {
    this.setValues();
    this.loading = true;
    this.rneService.putRne(this.rneSelectionne).subscribe({
      next:() => {
        this.loading = false;
        ToastTool.success('Rne modifié');
        this.rneModifie.emit();
        this.activeModal.close();
      },
      error: (err: HttpErrorResponse) => {
        this.loading = false;
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
        this.rneModifie.emit();
      }
    })
  }

  genererUai() {
    this.loading = true;
    this.rneService.gerenerUaiRne().subscribe({
      next: (data) => {
        this.loading = false;
        this.form.controls['uairne'].setValue(data);
      },
      error: (err) => {
        this.loading = false;
        ToastTool.danger(err.message());
      }
    })
  }
}

import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Rne} from "../../../models/rne";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {EtablissementClient} from "../../../models/etablissement-client";
import {RneService} from "../../../services/rne.service";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
  NgbNavChangeEvent,
  NgbNavContent,
  NgbTypeahead
} from "@ng-bootstrap/ng-bootstrap";
import {EtablissementService} from "../../../services/etablissement.service";
import {ToastTool} from "../../../tools/toast.tool";
import {HttpErrorResponse} from "@angular/common/http";
import {Crous} from "../../../models/crous";
import {CrousService} from "../../../services/crous.service";
import {TarificationService} from "../../../services/tarification.service";
import {Tarification} from "../../../models/tarification";
import {
  concat,
  debounceTime,
  delay,
  distinctUntilChanged,
  every,
  iif,
  map,
  mergeMap,
  Observable,
  of,
  OperatorFunction
} from "rxjs";
import {BceService} from "../../../services/bns/bce.service";
import {BceEtablissementSearch} from "../../../models/bce-etablissement-search";
import {BceEtablissement} from "../../../models/bce-etablissement";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-dialog-form-etablissement',
  templateUrl: './dialog-form-etablissement.component.html',
  styleUrls: ['./dialog-form-etablissement.component.css']
})
export class DialogFormEtablissementComponent {
  @Input() etablissementSelectionne: EtablissementClient;
  @Output() etablissementModifie = new EventEmitter<EtablissementClient>();
  form: UntypedFormGroup;
  loading: boolean = false;
  active = ACTIVEID.FICHE_ETABLISSEMENT;
  BCE_SIZE: number = 100;
  PAGE_NUMBER = 1;

  searchRneForm: UntypedFormGroup;

  listeCrous: Crous[] = [];
  listeBce: BceEtablissement[] = []
  listeTarif: Tarification[] = [];

  listeRneEtablissement: Rne[];

  listeAllBce: BceEtablissement[] = [];

  // Recherche de RNE
  formatter = (result: BceEtablissement) => {
    if (result.numeroUai) {
      return result.denominationPrincipale + ' (' + result.numeroUai + ')'
    }
  };

  search: OperatorFunction<string, readonly BceEtablissement[]> = (text$: Observable<string>) =>
    text$.pipe(
      tap((term) => {
        if (term.length >= 3) {
          this.listeAllBce = [];
          this.loadListAllRne(term);
        }
      }),
      delay(1000),
      debounceTime(600),
      map((term) => {
        return term === '' ? [] : this.listeAllBce.filter((v) => v.numeroUai.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
          v.denominationPrincipale?.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.commune?.libelle?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
          v.appellationOfficielle?.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, this.BCE_SIZE);
      }),
    );
  // --

  constructor(private etablissementService: EtablissementService,
              private crousService: CrousService,
              private bceService: BceService,
              private rneService: RneService,
              private tarificationService: TarificationService,
              private modalService: NgbModal,
              private fb: FormBuilder,
              private config: NgbModalConfig,
              public activeModal: NgbActiveModal) {
    this.config.backdrop = 'static';
    this.loadListeCrous();
  }

  loadListeCrous() {
    this.loading = true;
    const fields = "idCrous,libelle,numeroCrous";
    this.crousService.getListeAllCrous(fields).subscribe({
      next: (data) => {
        this.loading = false;
        this.listeCrous = data;
      },
      error: (err) => {
        this.loading = false;
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    });
  }

  loadListAllRne(text: string, page?: number) {
    let search: BceEtablissementSearch = new BceEtablissementSearch();
    search.fields = 'numeroUai,denominationPrincipale,commune,appellationOfficielle,sirenSiret';
    search.q = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    search.pageSize = this.BCE_SIZE;
    if (page) {
      search.pageNumber = page;
    }
    this.bceService.getListeBce(search).subscribe({
      next: (data) => {
        this.listeAllBce = data;
      },
      error: (err) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }

  openRneWindows(content: NgbNavContent) {
    this.modalService.open(content);
    this.form = this.fb.group({
      nom: this.fb.control<string|null>(null, Validators.required),
      adresse: this.fb.control<string|null>(null),
      crous: this.fb.control<Crous|null>(null, Validators.required),
    });
  }

  deleteRneFromEtablissement(bce: BceEtablissement) {
    this.loading = true;
    let rne: Rne = new Rne();
    rne.idRne = this.listeRneEtablissement.find(r => r.uairne === bce.numeroUai).idRne;
    this.etablissementService.deleteRneToEtablissementClient(this.etablissementSelectionne, rne).subscribe({
      next: () => {
        this.loading = false;
        this.listeBce = this.listeBce.filter(b => b.numeroUai !== bce.numeroUai);
        ToastTool.success('Rne détaché')
      },
      error: (err) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }

  addRne() {
    if (this.searchRneForm.controls['rne'].value) {
      const bce: BceEtablissement = this.searchRneForm.controls['rne'].value;
      let rne: Rne = new Rne();
      rne.uairne = bce.numeroUai;
      this.loading = true;
      this.etablissementService.addRneToEtablissementClient(this.etablissementSelectionne, rne).subscribe({
        next: () => {
          this.listeBce.push(bce);
          this.searchRneForm.controls['rne'].reset();
          this.loading = false;
          ToastTool.success('RNE ajouté');
        },
        error: (err) => {
          for (let error of err.error) {
            let description = error.error_description;
            ToastTool.danger(description);
          }
          this.loading = false;
        }
      });
    } else {
      ToastTool.danger('Veuillez sélectionner un RNE')
    }
  }

  ngOnInit(): void {

    this.form = this.fb.group({
      nom: this.fb.control<string|null>(null, Validators.required),
      adresse: this.fb.control<string|null>(null),
      crous: this.fb.control<Crous|null>(null, Validators.required),
    });

    this.searchRneForm = this.fb.group({
      rne: this.fb.control<any|null>(null)
    });

    if (this.etablissementSelectionne.idEtablissementClient) {
      this.initFormValues();
    } else {
      this.resetFormValues();
    }
  }

  initFormValues() {
    this.form.controls['nom'].setValue(this.etablissementSelectionne?.nom);
    this.form.controls['adresse'].setValue(this.etablissementSelectionne?.adresse);
    this.form.controls['crous'].setValue(this.toStr(this.etablissementSelectionne?.crous));
  }

  resetFormValues() {
    this.form.reset();
  }

  setValues() {
    this.etablissementSelectionne.nom = this.form.controls['nom']?.value;
    this.etablissementSelectionne.adresse = this.form.controls['adresse']?.value;
    this.etablissementSelectionne.crous = JSON.parse(this.form.controls['crous'].value);
  }

  save() {
    if (this.etablissementSelectionne.idEtablissementClient) {
      this.modifierEtablissement();
    } else {
      this.ajouterEtablissement();
    }
  }

  toStr(value: Crous): string {
    return JSON.stringify(value);
  }

  ajouterEtablissement() {
    this.setValues();
    this.loading = true;
    this.etablissementService.postEtablissementClient(this.etablissementSelectionne).subscribe({
      next:(data) => {
        this.loading = false;
        ToastTool.success('Etbalissement créé');
        this.etablissementSelectionne = data;
        this.etablissementModifie.emit();
      },
      error: (err: HttpErrorResponse) => {
        this.loading = false;
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
        this.etablissementModifie.emit();
      }
    });
  }

  modifierEtablissement() {
    this.setValues();
    this.loading = true;
    this.etablissementService.putEtablissementClient(this.etablissementSelectionne).subscribe({
      next:() => {
        this.loading = false;
        ToastTool.success('Etablissement modifié');
        this.etablissementModifie.emit();
      },
      error: (err: HttpErrorResponse) => {
        this.loading = false;
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
        this.etablissementModifie.emit();
      }
    })
  }

  loadlisteRne() {
    this.loading = true;
    const fields = "idRne," +
      "uairne," +
      "nomUsuel," +
      "libelleCourt," +
      "localite," +
      "etablissementClient.idEtablissementClient," +
      "etablissementClient.nom," +
      "etablissementClient.crous.numeroCrous";
    this.rneService.getRneFromEtablissement(this.etablissementSelectionne.idEtablissementClient, fields).subscribe({
      next: (data) => {
        this.listeRneEtablissement = data
        this.listeRneEtablissement.forEach(rne => {
          let bce: BceEtablissement = new BceEtablissement();
          bce.numeroUai = rne.uairne;
          let search: BceEtablissementSearch = new BceEtablissementSearch();
          search.fields = 'numeroUai,denominationPrincipale,commune,appellationOfficielle,sirenSiret';
          this.bceService.getBce(bce, search).subscribe(data => {
            this.listeBce.push(data);
          });
        })
        this.loading = false;
      },
      error: (err) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }

  loadListTarifs() {
    this.loading = true;
    const fields = "idEsist," +
      "indiceMin," +
      "indiceMax," +
      "codeSociete," +
      "codeTarif," +
      "tarificationStatut.codeStatut," +
      "tarificationStatut.libelle";
    this.tarificationService.getTarifByEtablissementClient(fields, this.etablissementSelectionne).subscribe({
      next: (data) => {
        this.listeTarif = data;
        this.loading = false;
      },
      error: (err) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
        this.loading = false;
      }
    })
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === ACTIVEID.RNE) {
      this.listeBce = [];
      this.loadlisteRne();
    } else if (changeEvent.nextId === ACTIVEID.TARIFS) {
      this.loadListTarifs();
    } else {
      this.initFormValues();
    }
  }

  protected readonly self = self;
}

enum ACTIVEID {
  FICHE_ETABLISSEMENT = 1,
  RNE,
  TARIFS
}
